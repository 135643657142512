import React from "react";
import "./Section1.css";

const Section1 = () => {
  return (
    <div id="section1" className="section1">
      <div className="welcome-container">
        <h1 className="welcome-title">Welcome to UW MSA</h1>
        <p className="welcome-text">
          The Muslim Students Association at the University of Washington is a
          vibrant community of students dedicated to fostering a welcoming and
          inclusive environment for all. We strive to uphold Islamic values,
          promote understanding, and create opportunities for spiritual, social,
          and academic growth.
        </p>
        <a href="#section3" className="cta-button">
          Upcoming Events
        </a>
      </div>
    </div>
  );
};

export default Section1;
